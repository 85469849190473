import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-after-confirmed-account-deletion-url",
  templateUrl: "./after-confirmed-account-deletion-url.component.html",
  styleUrls: ["./after-confirmed-account-deletion-url.component.css"],
})
export class AfterConfirmedAccountDeletionUrlComponent implements OnInit {
  ngOnInit(): void {
    // code
  }
}
