import { Component, OnInit } from "@angular/core";
import { ApiRequestService } from "app/services/api-request.service";

@Component({
  selector: "app-delete-account-form",
  templateUrl: "./delete-account-form.component.html",
  styleUrls: ["./delete-account-form.component.css"],
})
export class DeleteAccountFormComponent implements OnInit {

  loggedUserEmail: string = "";

  email: string = "";
  requestInProgress: boolean = false;
  feedbackMessage: string = "";
  isError: boolean = false;

  constructor(private apiRequestService: ApiRequestService) {}

  ngOnInit(): void {
    this.requestInProgress = true;
    this.apiRequestService.getOwnAccountInfo().subscribe(
      (response) => {
        console.log(response);
        this.loggedUserEmail = response.web_user.email || "";
      },
      (error) => {
        this.feedbackMessage = "Eroare de server. Încercați din nou mai târziu.";
        this.isError = true;
      },
      () => {
        this.requestInProgress = false;
      }
    );
  }

  async requestAccountDeletion(): Promise<void> {
    if (!this.email || this.email !== this.loggedUserEmail) {
      this.feedbackMessage = "Trebuie să introduceți aceeași adresă de email asociată contului.";
      this.isError = true;
      return;
    }

    this.requestInProgress = true;
    this.feedbackMessage = "";
    this.isError = false;

    this.apiRequestService.requestAccountDeletion().subscribe(
      (response) => {
        if (response.success) {
          this.feedbackMessage = "Solicitarea dumneavoastră a fost trimisă cu succes.";
          this.isError = false;
        } else {
          this.feedbackMessage = response.data['message'] || "Eroare: Nu am reușit să procesăm cererea.";
          this.isError = true;
        }
      },
      (error) => {
        this.feedbackMessage = "Eroare de server. Încercați din nou mai târziu.";
        this.isError = true;
      },
      () => {
        this.requestInProgress = false;
      }
    );
  }
}
